
import { defineComponent } from 'vue'
import MdiAlert from '@/components/base/icons/mdi/MdiAlert.vue'
import { useTranslation } from '@/locales'

export default defineComponent({
  components: {
    MdiAlert,
  },
  setup(props) {
    const { t } = useTranslation()
    return { t }
  },
})
